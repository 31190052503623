/************ Custom Font ************/
@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Book.ttf");
  font-weight: 400;
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
  font-family: "Centra", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

@media (min-width: 1700px) {
  main .container {
    max-width: 100%;
    padding: 0 150px;
  }
}

p.success {
  color: green;
}

p.danger {
  color: red;
}

/************ Navbar Css ************/
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
}
nav.navbar.scrolled {
  background-color: #121212 !important;
}

nav.navbar a.navbar-brand {
  height: 42px;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 10px 25px;
  font-size: 18px;
  opacity: 0.75;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
}

span.navbar-text {
  display: flex;
  align-items: center;
}
.social-icon {
  display: inline-block;
  margin-left: 14px;
}

.social-icon a {
  width: 42px;
  height: 42px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
  transform: scale(1);
}
.social-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%)
    hue-rotate(346deg) brightness(95%) contrast(86%);
}
.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 14px;
  margin-left: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #121212;
}
.navbar-text button:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: #fff;
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: #fff;
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

@media (min-width: 430px) and (max-width: 978px) {
  nav.navbar a.navbar-brand {
    display: none;
  }
}

@media (max-width: 430px) {
  nav.navbar {
    display: none;
  }
}

/************ Banner Css ************/
.banner {
  margin-top: 0;
  padding: 20% 0 8% 0;
  background-image: url("./assets/img/banner-bg2-onlytop.png");
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.15);
}
.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(
    90.21deg,
    rgba(170, 54, 124, 0.5) 0%,
    rgba(74, 47, 189, 0.5) 100%
  );
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}
.banner h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}
.banner p {
  color: #ffffff;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}
.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px;
}
.banner img {
  animation: updown 3s linear infinite;
}
.job-tagline {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
  color: #b8b8b8;
}

@media (max-width: 430px) {
  .banner {
    padding: 20% 0% 35% 0%;
  }
  .banner button {
    margin-top: 15px;
  }
  .aboutme-bx {
    padding: 40px 25px !important;
  }
  .profilepic {
    padding: 20px 20px 0px 20px !important;
  }
}

/************ About Me Css ************/
.aboutme {
  padding: 0 0 50px 0;
  position: relative;
}
.aboutme-bx {
  background: #151515;
  border-radius: 64px;
  text-align: left;
  padding: 60px 70px;
  margin-top: -40px;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25),
    0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
}
.aboutme h2 {
  font-size: 45px;
  font-weight: 700;
  margin: 15px 0 30px 0;
}
.aboutme p {
  color: #b8b8b8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
}
.aboutme-txt {
  padding: 0px 0px 75px 0px;
}
.overview-skills-bx {
  padding: 20px 80px 20px 80px;
  position: relative;
  display: block;
}

.item {
  margin: 0px 25px 0px 25px;
  text-align: center;
}
.item img {
  padding: 30px 30px 30px 30px;
}
.background-image-left {
  top: 28%;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}
.profilepic {
  border-radius: 50%;
  padding: 0px 20px 0px 20px;
}

/* Detailed skills css */
.detail-skill-bx .detail-skill-bar {
  height: 6px;
  width: 100%;
  border-radius: 6px;
  margin-top: 6px;
  background: rgba(74, 74, 74, 0.1);
}

.detail-skill-bx .detail-skill-per {
  position: relative;
  display: block;
  height: 100%;
  width: 95%;
  border-radius: 6px;
  background: linear-gradient(90.21deg, #dc42b0 0%, #5c0989 100%);
  animation: progress 0.4s ease-in-out forwards;
  opacity: 0;
}

/*Technical skills*/
.detail-skill-per.sql {
  width: 100%;
  animation-delay: 0.1s;
}

.detail-skill-per.webdev {
  width: 100%;
  animation-delay: 0.2s;
}
.detail-skill-per.jslib {
  width: 60%;
  animation-delay: 0.3s;
}
.detail-skill-per.python {
  width: 50%;
  animation-delay: 0.3s;
}
.detail-skill-per.solidity {
  width: 35%;
  animation-delay: 0.3s;
}
.detail-skill-per.mongodb {
  width: 25%;
  animation-delay: 0.3s;
}

/*Language skills*/
.detail-skill-per.english {
  width: 100%;
  animation-delay: 0.1s;
}

.detail-skill-per.swedish {
  width: 100%;
  animation-delay: 0.2s;
}
.detail-skill-per.german {
  width: 65%;
  animation-delay: 0.3s;
}
.detail-skill-per.polish {
  width: 65%;
  animation-delay: 0.3s;
}
.detail-skill-per.serbian {
  width: 45%;
  animation-delay: 0.3s;
}
.detail-skill-per.spanish {
  width: 25%;
  animation-delay: 0.3s;
}

.detail-skill-category-bx {
  padding: 20px 80px 20px 80px;
}

.detail-skill-title {
  text-align: left;
}

.detail-skill .skills-header-mobile {
  display: none;
}

@keyframes progress {
  0% {
    width: 0;
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@media (max-width: 978px) {
  .detail-skill-category-bx {
    padding: 0 0 0 0;
  }

  .item {
    padding: 0px 0px 25px 0px;
    margin: 0px 25px 0px 25px;
    text-align: center;
  }
  .item img {
    padding: 10px 10px 10px 10px;
  }

  .detail-skill-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .detail-skill .skills-header-mobile {
    display: block;
  }
}

/************ Timeline Css ************/
.experience {
  padding: 100px 0 100px 0;
  position: relative;
}

.experience h2 {
  font-size: 45px;
  font-weight: 700;
  text-align: center;
  padding: 0 0 50px 0;
}

.experience p {
  color: #b8b8b8 !important;
  font-size: var(--bs-body-font-size) !important;
  font-weight: var(--bs-body-font-weight) !important;
  letter-spacing: 0.8px !important;
  line-height: 1.5em !important;
  margin: 14px 0 25px 0 !important;
}

.vertical-timeline-element-content {
  background: #151515 !important;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25),
    0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
  padding: 2em 3em !important;
}

.experience#description {
  margin: 1.5em 0 2em 0;
}

.experience .button {
  text-decoration: none;
  padding: 0.5em 1em;
  border-radius: 5px;
  color: #fff;
  background-color: #5c0989 !important;
}

.experience .button:hover {
  background-color: #36228e !important;
}

.vertical-timeline-element-icon {
  fill: #fff;
  background: -moz-linear-gradient(right, #dc42b0 0%, #5c0989 100%) !important;
  background: -webkit-linear-gradient(
    right,
    #dc42b0 0%,
    #5c0989 100%
  ) !important;
  background: linear-gradient(to right, #dc42b0 0%, #5c0989 100%) !important;
}

.vertical-timeline-element-content-arrow {
  border-right: 7px solid #b8b8b8 !important;
}

.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}

.experience .expertise-label {
  cursor: inherit;
  display: flex;
  align-items: center;
  user-select: none;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.87);
  height: 32px;
  border: none;
  display: inline-flex;
  outline: none;
  margin: 5px;
  padding: 10px;
  font-size: 0.85rem;
  font-weight: var(--bs-body-font-weight) !important;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  align-items: center;
  border-radius: 16px;
  justify-content: center;
  text-decoration: none;
  background-color: #e0e0e0;
}

@media (max-width: 978px) {
  .vertical-timeline-element-date {
    display: block !important;
    float: none !important;
    margin-top: 1.5em;
  }
  .experience .button {
    display: none;
  }
}

@media (max-width: 430px) {
  .experience {
    padding: 50px 0px 80px 0px;
  }
}

/************ Contact Css ************/
.contact {
  margin-top: 0;
  padding: 10%;
  background-image: url("./assets/img/banner-bg2-onlybottom.png");
  background-position: bottom center;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.15);
  text-align: center;
}
.contact-bx {
  padding: 10% !important;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
}
.contact h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}
.contact p {
  color: #fff;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
}
.contact button {
  color: #fff;
  font-weight: 700;
  font-size: 30px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  align-items: center;
}
.contact button svg {
  font-size: 38px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.contact button:hover svg {
  margin-left: 25px;
}
.contact img {
  animation: updown 3s linear infinite;
}

/* Footer */
.footer {
  padding: 30px 0 30px 0;
  background-color: #000000;
}

.footer img {
  width: 25%;
}
.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #b8b8b8;
  letter-spacing: 0.5px;
  margin-top: 20px;
}
